import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Button } from '../../common/button';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import { resolve } from '../../../helpers/urls';
import './facts.scss';

const bem = new BEMHelper({
  name: 'content-facts',
});

export const Facts = ({
  id,
  headline,
  facts: [{ facts }],
  reference,
}) => {
  const { t } = useTranslation();
  const hasIcons = facts.reduce(
    (acc, { icon: [icon] }) => acc || icon !== undefined,
    false,
  );

  let to;
  if (reference?.element) {
    to = resolve('pages:detail', {
      pageSlug: reference.element.slug,
      departmentSlug: reference.element.departments[0]?.slug,
    });
  }

  return (
    <Content id={id} {...bem()} useOwnSpacing>
      <h2 {...bem('headline')}>{headline}</h2>
      <ul {...bem('list', { 'has-icons': hasIcons })}>
        {facts.map(({
          headline: title,
          description,
          footnotes,
          icon: [icon],
        }, index) => {
          const key = `${headline}-${index}`;
          return (
            <li key={key} {...bem('list-item')}>
              <h3 {...bem('title', { justify: !description })}>{title}</h3>
              {description && (<div {...bem('description')}>{parse(description)}</div>)}
              {footnotes && (<div {...bem('footnotes')}>{parse(footnotes)}</div>)}
              {icon && (
                <FluidPicture
                  {...icon}
                  {...bem('icon')}
                  widths={[80]}
                  role="presentation"
                />
              )}
            </li>
          );
        })}
      </ul>
      {reference?.element && (
        <Button
          variant="primary"
          to={to}
          title={reference.title
            ? reference.title
            : t('Read more about "{{ topic }}"', { topic: headline })}
          {...bem('link')}
        >
          {reference.text}
        </Button>
      )}
    </Content>
  );
};

Facts.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  facts: arrayOf(shape({
    facts: arrayOf(shape({
      headline: string.isRequired,
      description: string,
      footnotes: string,
      icon: arrayOf(shape({
        fluid_0: string,
      })),
    })),
  })).isRequired,
  reference: shape({
    element: shape({
      slug: string.isRequired,
      departments: arrayOf(shape({
        slug: string.isRequired,
      })),
    }),
    text: string.isRequired,
  }),
};

Facts.defaultProps = {
  reference: null,
};

export const fragment = graphql`
  fragment FactsContentFragment on CraftGQL_pageContents_facts_BlockType {
    id
    headline
    facts {
      ...on CraftGQL_pageContentsFacts_contentsFacts_Entry {
        facts: contentFacts {
          ...on CraftGQL_contentFacts_fact_BlockType {
            headline
            description
            footnotes
            icon {
              ...Picture
              fluid_0: url(width: 80, mode: "crop", position: "")
            }
          }
        }
      }
    }
    reference {
      text
      title
      element {
        ...on CraftGQL_pages_pages_Entry {
          slug: pageSlug
          departments: pageDepartments {
            slug
          }
        }
      }
    }
  }
`;
